@import "../../../../styles/variables.scss";

$selected-bg-color: #FF9B42;

.background-block-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .background-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: $size-10;
    row-gap: $size-10;
    margin-left: $size-6;

    &:first-of-type {
      border-right: $size-1 solid #FFFFFF;
    }

    .color {
      position: relative;
      border-radius: $size-1;
      width: $size-20;
      height: $size-20;
      cursor: pointer;

      svg {
        position: absolute;
      }

      .color--text {
        z-index: 1;
        position: absolute;
        display: inline-block;
        width: $size-20;
        height: $size-20;
        text-align: center;
        font-size: $size-10;
        line-height: $size-19;
      }

      &.remove {
        position: absolute;
        right: -$size-3;
        top: -$size-3;
      }

      &.selected {
        box-shadow: 0 0 0 $size-1 $selected-bg-color;
      }
    }
  }
}

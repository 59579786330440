@import "../../../styles/variables.scss";

.order-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .order-status-btn {
    border-radius: $size-5;
    padding: $size-8 $size-10;
    display: flex;
    background-color: transparent;

    &:hover {
      background-color: $component-bg-hover;
    }
  }

  .status-list{
    position: absolute;
    top: $size-42;
    left: 0;
    width: min-content;
    min-width: $size-170;
    background-color: $header-bg;
    border: $size-1 solid $component-border-color;
    border-radius: $size-2;
    padding: $size-12;
    margin-top: $size-4;

    .status-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      padding: $size-6 $size-12;
      border-radius: $size-3;
      white-space: nowrap;
      cursor: pointer;

      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;
      }

      .status-color {
        width: $size-24;
        height: $size-24;
        border-radius: 50%;
        margin-right: $size-16;

        &.orange {
          background-color: #ff9b42;
        }

        &.red {
          background-color: #db5375;
        }
      }

      svg {
        position: relative;
        margin-right: $size-12;
        width: $size-24;
      }

      span {
        font-size: $size-14;
        color: $component-color;
      }
    }
  }
}

@import "../../../styles/variables";

.header--filters__account {
  position: relative;

  .account_menu {
    position: absolute;
    top: $size-42;
    right: 0;
    width: min-content;
    min-width: $size-170;
    background-color: $header-bg;
    border: $size-1 solid $component-border-color;
    border-radius: $size-2;
    padding: $size-12;
    margin-top: $size-4;

    .account-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      padding: $size-6 $size-12;
      border-radius: $size-3;

      &.active {
        background-color: $component-bg-active;

        .status-color {
          border: $size-1 solid #fff;
        }
      }

      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;

        .status-color {
          border: $size-1 solid #fff;
        }
      }

      .status-color {
        width: $size-24;
        height: $size-24;
        border-radius: 50%;
        margin-right: $size-16;

        &.green {
          background-color: #8acb88;
        }

        &.orange {
          background-color: #ff9b42;
        }

        &.red {
          background-color: #db5375;
        }
      }

      svg {
        margin-right: $size-12;
      }

      span {
        font-size: $size-14;
        color: $component-color;
      }
    }
  }
}

.profile-picture {
  max-width: $size-34;
  max-height: $size-34;
  border-radius: 50%;
}

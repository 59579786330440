@import "../../styles/variables";

.toggle-container {
  display: flex;
  align-items: center;

  .toggle {
    display: none;
  }

  label.toggle-icon {
    cursor: pointer;
    width: $size-24;
    height: $size-12;
    background: #F2F4F7;
    display: block;
    border-radius: $size-100;
    position: relative;
  }

  label.toggle-icon:after {
    content: "";
    position: absolute;
    top: $size-1;
    left: $size-2;
    width: $size-10;
    height: $size-10;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + label.toggle-icon {
    background: #8EA49D;
  }

  input:checked + label.toggle-icon:after {
    left: calc(100% - $size-2);
    transform: translateX(-100%);
  }
}

@import '../../styles/reset';

.header-logo--wrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: $header-bg;
  text-align: left;
  padding-top: $size-11;
  padding-left: $size-11;

  .header-logo {
    cursor: pointer;
  }
}

.error-page {
  display: flex;
  height: 100vh;

  .error--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .error-type {
    color: $error-type-color;
    height: $size-43;
  }

  .error-title {
    padding: 0 $size-60;

    h2 {
      color: $not-found-color;
    }
  }

  .error-descr {
    p {
      color: $error-descr-color;
    }
  }

  @media (min-width: $mobile-breakpoint-width) {
    .error-type {
      height: $size-61;
    }
  }
}
@import "../../../../styles/variables.scss";

.collapsible-image-info {
  z-index: $z-index-overlay-controls;
  position: absolute;
  top: $size-20;
  left: 0;
  min-height: $size-220;
  background-color: #333333b2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top-right-radius: $size-5;
  border-bottom-right-radius: $size-5;

  .collapse-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $size-15;
    cursor: pointer;
    background-color: #33333380;
    border-top-right-radius: $size-5;
    border-bottom-right-radius: $size-5;
  }

  .collapsible-image-info__content {
    display: flex;
    flex-direction: column;
    padding: $size-20 0;

    .info {
      &.info-error {
        background-color: transparent;
      }
      
      .image-error {
        background-color: #DB5375;
        color: #fff;
      }
    }
  }
}

@import "../../../../styles/variables";

.update-prices-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: $size-10;

  .update-prices-dialog__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: $size-22;
    position: relative;

    .update-prices-dialog__price-error {
      position: absolute;
      bottom: $size-3;

      p {
        font-size: $size-11;
        white-space: nowrap;
      }
    }

    input {
      width: $size-120;
      padding: 0 $size-5;
      height: $size-30;
      line-height: $size-30;

      &:focus {
        outline: none;
      }
    }
  }

  .update-prices-dialog__delete-button {
    background-color: transparent;
  }
}

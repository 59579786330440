@import '../../styles/reset';

.general-loading-icon {
  .loading-dots {
    width: auto;

    .loading-dots--dot {
      width: $size-10;
      height: $size-10;
    }
  }
}

.background-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $size-5;
  margin-bottom: $size-6;

  > .color {
    cursor: pointer;
    position: relative;
    top: $size-1;
    width: $size-12;
    height: $size-12;
    border-radius: $size-1;

    > svg {
      position: absolute;
      width: $size-12;
      height: $size-11;
    }
  }

  .gallery-background {
    font-size: $size-12;
  }
}

@import '../../styles/reset';

$dialog-color: #FFFFFF;
$dialog-bg-color: #333333;

$dialog-cancel-color: #333333;
$dialog-cancel-bg-color: #F8F8F8;

$dialog-yes-color: #FFFFFF;
$dialog-yes-bg-color: #5B7FBE;

.modal--wrap {
  z-index: $z-index-modal;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.33);

  .modal {
    display: flex;
    flex-direction: column;

    position: relative;
    left: 50%;
    top: $center-top;
    transform: translate(-50%, (-$center-top));

    border-radius: $size-12;

    color: $dialog-color;
    background-color: $dialog-bg-color;
    box-shadow: 0 0 $size-37 (-$size-4) rgba(0, 0, 0, 0.2);

    width: $size-400;
    max-width: 90vw;
    min-height: $size-140;
    padding: $size-24 $size-24;

    .check-item {
      color: $dialog-color;
    }

    .modal-btn-container {
      min-width: $size-172;
    }

    .dialog-button {
      width: 100%;
      border-radius: $size-4;
      height: $size-36;
      font-size: $size-14;
      font-family: $font-semibold;

      &.cancel {
        color: $dialog-cancel-color;
        background-color: $dialog-cancel-bg-color;

        &:hover {
          background-color: darken($dialog-cancel-bg-color, 5%
          );
        }
      }

      &.yes {
        color: $dialog-yes-color;
        background-color: $dialog-yes-bg-color;

        &:hover {
          background-color: darken($dialog-yes-bg-color, 5%);
        }
      }
    }
  }

  .modal-small {
    width: $size-400;
  }

  .modal-medium {
    width: $size-600;
  }

  .modal-large {
    width: $size-700;
  }

  .modal-xl {
    width: $size-700;
  }

  .modal--center {
    text-align: center;
  }
}
@import '../../../styles/reset';

.header {
  .header--counters__count--wrap {
    display: flex;
    text-align: center;
    margin-bottom: $size-7;

    .header--counter {
      margin: 0 $size-2;
      padding: $size-4 $size-8;
      border-radius: $size-4;

      &:hover {
        background-color: $component-bg-active;
      }

      &.active {
        background-color: $component-bg-active;
      }

      &.disabled {
        color: $component-color-disabled;

        svg {
          path {
            stroke: $component-color-disabled;

            &.svg-to-fill {
              fill: $component-color-disabled;
            }
          }

          circle {
            stroke: $component-color-disabled;
          }
        }
      }

      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;
      }
    }

    .header--counters__icon {
      height: $size-18;
      text-align: center;
    }

    .header--counters__icon svg {
      position: relative;
      text-align: center;
    }

    .header--counters__value {
      font-family: $font-semibold;
      font-size: $size-12;

      &.header--counters--is-notifications {
        color: #FF9B42;
      }

      &.header--counters--is-errors {
        color: #DB5375;
      }

      &.header--counters--is-comments {
        color: #FFFFFF;
      }

      &.header--counters--is-confirmed {
        color: #8ACB88;
      }

      &.header--counters--is-missing {
        color: #FF9B42;
      }

      &.disabled {
        color: $component-color-disabled;
      }
    }
  }
}

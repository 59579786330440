@import '../../../styles/reset';

.header {
  .header--order-id__wrap {
    display: flex;
    flex-direction: column;
    width: $size-56;
    color: #fff;
    font-family: $font-semibold;
    text-align: center;
    margin-left: $size-10;
    margin-right: $size-10;
    padding-top: $size-4;

    .header--order-id__wrap--header {
      font-size: $size-10;
      line-height: $size-9;
    }

    .header--order-id__wrap--value {
      padding-top: $size-3;
      font-family: $font-semibold;
      font-size: $size-12;
    }
  }
}

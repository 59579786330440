@import '../../../styles/variables.scss';

$actions-bg: $header-bg;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  z-index: $z-index-preview;
  position: fixed;
  bottom: $size-16;
  right: 50%;
  transform: translateX(50%);
  @include flex-center;
  flex-direction: row;
  background-color: $actions-bg;
  border-radius: $size-4;
  padding: $size-6 $size-12;
  gap: $size-5;
  height: $size-46;

  .selected-count {
    color: #FFF;
    font-size: $size-20;
    font-family: $font-semibold;
    width: $size-40;
    height: $size-34;
    background-color: $image-wrap-selected-bg-color;
    border-radius: $size-5;
    cursor: pointer;
    @include flex-center;
  }

  .separator {
    width: $size-1;
    height: $size-21;
    background-color: $component-border-color;
  }

  .actions__button {
    position: relative;
    background-color: $actions-bg;
    @include flex-center;
    border: none;
    width: $size-40;
    height: $size-34;
    border-radius: $size-5;
    cursor: pointer;
    outline: none;

    &.disabled {
      svg {
        path {
          fill: $component-color-disabled;
        }
      }
    }

    &:hover {
      background-color: $component-bg-hover;
    }

    .button-menu-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $size-2;
    }

    .button-menu {
      @include flex-center;
      flex-direction: column;
      flex-wrap: nowrap;
      background-color: $actions-bg;
      position: absolute;
      bottom: $size-44;
      gap: $size-5;
      border-radius: $size-4;
      padding: $size-6;
    }
  }
}

@import "../../../styles/reset";

$header-controls-container-height: $size-370;

@mixin control-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: $component-bg;
  border: $size-1 solid $component-border-color;
  border-radius: $menu-border-radius;
  color: #ffffff;
  padding: $size-11 $size-12 $size-14 $size-12;
  width: fit-content;
  min-width: $size-200;
  max-height: $header-controls-container-height;

  .container-title {
    @include container-title;
  }

  .separator {
    width: 100%;
    margin-bottom: $size-10;
    border-top: $size-1 solid $component-border-color;
  }
}

@mixin container-title {
  font-size: $size-12;
  margin-bottom: $size-10;
  white-space: nowrap;
  font-family: $font-semibold;
}

.header {
  .header--controls__wrap {
    height: $header-controls-height;
    padding: $size-5 $size-8;
    border: $size-1 solid $component-border-color;
    border-radius: $size-5;
  }

  .header--controls__icon {
    position: relative;
    width: $size-30;
    height: $size-26;
    padding: $size-5 $size-4;
  }

  .header--controls__control {
    z-index: $z-index-gallery--menu;
    position: relative;
    width: $size-30;
    height: $size-26;
    border-radius: $size-2;
    text-align: center;
    margin-right: $size-4;

    .radio-icon {
      display: flex;
      align-items: center;
    }

    &.disabled {
      svg {
        path {
          fill: $component-color-disabled;
        }
      }
    }

    &:not(.disabled):not(.active):hover {
      background-color: $component-bg-hover;
    }

    &:last-of-type {
      margin-right: 0;
    }

    ul {
      position: absolute;
      top: $size-22;
      right: 0;
    }

    svg {
      position: relative;
    }
  }

  .toggle-container {
    margin-bottom: $size-8;
    gap: $size-6;

    label {
      font-size: $size-12;
    }

    input:checked + label.toggle-icon {
      background: #8acb88;
    }
  }

  .header--controls__container {
    z-index: $z-index-header;
    position: relative;
    top: $size-2;
  }

  .paths-container {
    @include control-container;

    .paths-check-list {
      width: 100%;
      max-height: $size-260;
    }

    .line-width-label {
      margin-bottom: $size-8;
      font-size: $size-12;
      font-family: $font-semibold;
    }

    .slider-container {
      margin-bottom: $size-8;

      .slider {
        width: $size-154;
      }
    }

    .path-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-6;
      text-align: left;
      padding: 0 $size-4 0 $size-4;
      white-space: nowrap;
      border-radius: $size-2;
      margin-bottom: $size-8;

      .checkbox-container {
        width: 100%;

        label:last-of-type {
          width: 100%;
        }
      }

      &:hover {
        background-color: $component-bg-hover;
      }

      .path-color {
        position: relative;
        border-radius: $size-1;
        width: $size-10;
        height: $size-10;
        min-width: $size-10;
        min-height: $size-10;

        &:hover {
          cursor: pointer;
        }

        .compact-picker {
          width: $size-200 !important;

          .flexbox-fix {
            & > div:nth-of-type(2) {
              input {
                width: 100% !important;
              }
            }
          }
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .path-item--counter {
        font-size: $size-8;
        white-space: nowrap;
      }
    }
  }

  .grids-container {
    @include control-container;

    .checkbox-container {
      width: 100%;

      label:last-of-type {
        width: 100%;
      }
    }

    .grid-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-5;
      width: 100%;
      margin-bottom: $size-5;
      border-radius: $size-2;
      padding: 0 $size-4 0 $size-4;
      text-align: left;

      &:hover {
        background-color: $component-bg-hover;
      }

      .grid-item-color {
        position: relative;
        border-radius: $size-1;
        width: $size-10;
        height: $size-10;
        min-width: $size-10;
        min-height: $size-10;

        &.no-color {
          background-color: transparent;
        }
      }

      .grid-item-template-name {
        color: #6b6b6b;
      }
    }

    .show-more {
      width: 100%;
      text-align: right;
      text-decoration: underline;
      font-size: $size-12;
    }

    .separator {
      margin-bottom: $size-5;
    }
  }

  .preview-type-container {
    @include control-container;

    .preview-type {
      display: flex;
      flex-direction: row;
      border: $size-1 solid $component-border-color;
      border-radius: $size-4;
      padding: $size-14 $size-16;
      gap: $size-20;

      .preview-type-radio {
        display: none;
      }

      &:not(:last-of-type) {
        margin-bottom: $size-8;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $size-5;

        .left-top {
          display: flex;
          flex-direction: row;
        }
      }

      &.list {
        .right {
          justify-content: center;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $size-5;
        margin-bottom: $size-6;

        .right-top {
          display: flex;
          flex-direction: row;
          gap: $size-7;

          .btn-gallery-size {
            background-color: transparent;
            color: #ffffff;
            white-space: nowrap;
            font-size: $size-9;
            line-height: $size-12;
            padding: $size-6 $size-10;
            border-radius: $size-5;

            &.active {
              background-color: $component-bg-active;
            }

            &:not(.disabled):not(.active):hover {
              background-color: $component-bg-hover;
            }
          }
        }

        .right-bottom {
          .image-size {
            .slider-container {
              margin-top: $size-20;
            }
          }

          .slider {
            width: $size-88;
          }

          .grid-size {
            .grid-size-desc {
              font-size: $size-10;
              text-align: left;
              margin: 0 0 $size-6;

              span {
                background-color: #6a6a6a;
                font-size: $size-10;
                padding: 0 $size-6;
                border-radius: $size-1;
              }
            }
          }

          .notice {
            background-color: $notice-color;
            border-radius: $size-1;
            font-size: $size-8;
            margin-top: $size-8;
            padding: 0 $size-3;
          }
        }

        .description {
          font-size: $size-10;
          text-align: left;
          font-style: italic;
        }
      }

      .preview-type--label {
        margin-left: $size-3;
      }
    }
  }

  .properties-container {
    @include control-container;
    max-height: inherit; /* removes vertical scrolling */

    .checkbox-container {
      width: 100%;

      label:last-of-type {
        width: 100%;
      }
    }

    .property {
      margin-bottom: $size-4;
      padding-left: $size-4;
      border-radius: $size-2;
      width: 100%;
      text-align: left;

      &:hover {
        background-color: $component-bg-hover;
      }
    }
  }

  .layers-container {
    @include control-container;

    .layer-item {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: $size-8;

      gap: $size-4;
      padding: 0 $size-4 0 $size-4;
      white-space: nowrap;
      border-radius: $size-2;
      text-align: left;

      &:hover {
        background-color: $component-bg-hover;
      }

      label:last-of-type {
        width: 100%;
      }

      .layer-radio {
        display: none;
      }

      .layer-count {
        font-size: $size-12;
      }
    }

    .mask-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: $size-8;

      .toggle-container {
        margin-bottom: 0;
      }

      .mask-desc {
        color: #6b6b6b;
      }

      .mask-count {
        font-size: $size-12;
      }
    }
  }

  .background-container {
    padding: 0;
    display: flex;
    flex-direction: row;
    min-width: $size-268;
    border-radius: $menu-border-radius;
    overflow: visible;

    .container-title {
      @include container-title;
    }

    .left {
      background-color: $component-bg;
      border-top: $size-1 solid $component-border-color;
      border-left: $size-1 solid $component-border-color;
      border-bottom: $size-1 solid $component-border-color;
      border-top-left-radius: $menu-border-radius;
      border-bottom-left-radius: $menu-border-radius;
      color: #ffffff;
      padding: $size-14 $size-14 $size-8 $size-16;
      min-width: $size-165;
      text-align: left;
      display: flex;
      flex-direction: column;

      .description {
        font-size: $size-8;
        font-style: italic;
      }
    }

    .right {
      border-top: $size-1 solid $component-border-color;
      border-right: $size-1 solid $component-border-color;
      border-bottom: $size-1 solid $component-border-color;
      border-top-right-radius: $menu-border-radius;
      border-bottom-right-radius: $menu-border-radius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $size-5;
      background-color: #566f7c;
      width: 100%;
      height: $size-160;
    }

    .separator {
      width: 100%;
      margin-bottom: $size-10;
      border-top: $size-1 solid $component-border-color;
    }
  }
}

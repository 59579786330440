@import '../../../styles/reset';

.header {
  .header--avatars__wrap--wrap {
    min-width: $size-86;
  }

  .header--avatars__wrap {
    flex-direction: row-reverse;
    margin-right: $size-10;

    .header--avatar {
      position: relative;
      width: $size-38;
      height: $size-38;
      line-height: calc($size-38 - $header-avatar-border);
      color: #fff;
      background-color: $component-bg-active;
      border: $header-avatar-border solid $header-bg;
      font-family: $font-semibold;
      font-size: $size-12;
      border-radius: 50%;
      text-align: center;
    }

    .header--avatar__counter {
      background-color: #fff;
      color: #000;
    }

    .header--avatar__client {
      top: $size-1;
      width: $size-36;
      height: $size-36;
      line-height: calc($size-36 - $header-avatar-border);
      color: $header-bg;
      background-color: #FFF !important;
      border: $header-avatar-border dotted $header-bg;
    }

    .header-users {
      background-color: $header-bg;
      border-radius: $size-2;
      border: $size-1 solid $component-bg-active;
      color: #fff;
      position: absolute;
      top: $size-48;
      max-width: $size-200;
      padding: $size-8;
      
      span {
        font-size: $size-12;
      }
    }
  }
}


@import '../../styles/variables';

.header-logo--wrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: $header-bg;
  text-align: left;
  padding-top: $size-11;
  padding-left: $size-11;

  .header-logo {
    cursor: pointer;
  }
}

.error-page {
  display: flex;
  height: 100vh;

  .error--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .error-type {
    color: #333333;
    height: $size-43;
  }

  .error-title {
    padding: 0 $size-60;

    h2 {
      color: $not-found-color;
    }
  }

  .error-descr {
    h6 {
      color: #F5F5F5;
    }
  }

  .button-take-home {
    margin: inherit $size-35;
    background-color: #333333;
    color: #FFFFFF;
    width: fit-content;
    height: $size-44;
    line-height: $size-44;
    padding: 0 $size-20;
    font-size: $size-16;
    font-family: $font-semibold;
    border-radius: $size-4;

    &:hover {
      background-color: $component-bg-hover;
      transition: background-color 0.5s;
    }
  }

  @media (min-width: $mobile-breakpoint-width) {
    .error-type {
      height: $size-61;
    }
  }
}
@import '../../../../styles/variables.scss';

.overlay--comment__wrap {
  position: relative;
  display: flex;
  padding: $size-4 $size-10 $size-4 $size-24;

  .overlay--comment__icon {
    position: relative;
    width: $comment-icon-client-size;
    height: $comment-icon-client-size;
    min-width: $comment-icon-client-size;
    min-height: $comment-icon-client-size;
    margin-right: $size-6;
  }

  .overlay--comment__icon svg {
    position: absolute;
  }

  .overlay--comment__icon--status svg {
    top: $size-1;
    left: $size-3;
  }

  .overlay--comment__type svg {
    top: $size-4;
  }

  .overlay--comment__icon--client {
    top: $size-3;
    font-family: $font-semibold;
    font-size: $size-8;
    line-height: $comment-icon-client-size;
    border-radius: $size-2;
    text-align: center;
    margin-right: $size-8;
    background-color: $client-comment-bg;
  }

  .overlay--comment__icon--client__approved {
    background-color: $client-comment-approved-bg;
  }

  .overlay--comment {
    font-size: $size-12;
    line-height: $size-20;

    .overlay--comment__note {
      padding-left: $size-3;
      color: #434446;
    }
  }

  .overlay--comment__version {
    position: relative;
    top: $size-3;
    left: $size-3;
    margin-right: $size-6;
  }

  .overlay--comment__download {
    display: none;
    position: absolute;
    right: $size-12;
    top: $size-8;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &.overlay--comment__wrap--deleted {
    color: #67686A;

    svg {
      path {
        stroke: $component-color-disabled;
      }

      rect {
        stroke: $component-color-disabled;
      }
    }

    .overlay--comment__icon--client {
      background-color: #4B4C4E;
      color: #6C6C6C;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $component-bg-hover;

    .overlay--comment__download {
      display: flex;
    }
  }

  .checkbox-icons {
    padding-left: $size-0-5;
    margin-right: $size-11;
  }
}

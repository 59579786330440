@import "../../../styles/reset";

$filter-button-text-color: #6c6c6c;

.header {
  .header--filters__wrap {
    .header--filters__combine,
    .header--filters__save,
    .header--filters__chat,
    .header--filters__account {
      cursor: pointer;
      width: $header-controls-width;
      height: $header-controls-height;
      line-height: $header-controls-height;
      margin: 0 $size-5;
      border-radius: $size-5;
      text-align: center;
    }

    .header--filters__account {
      width: $header-controls-height;
      height: $header-controls-height;
      line-height: $header-controls-height;
      background-color: $component-color-disabled;
      border: none;
      font-family: $font-semibold;
      font-size: $size-12;
      border-radius: 50%;

      a {
        color: #fff;
        text-decoration: none;
        border: none;
      }
    }

    .header--filters__combine:hover,
    .header--filters__save:hover,
    .header--filters__chat:hover {
      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;
      }
    }

    .header--filters__combine svg,
    .header--filters__save svg {
      position: relative;
      top: $size-2;
    }

    .header--filters__combine {
      &.disabled {
        svg {
          path {
            fill: $component-color-disabled;
          }
        }
      }

      &.active {
        background-color: $component-bg-active;
      }

      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;
      }
    }

    .header--filters__chat svg {
      position: relative;
      top: $size-3;
    }

    $header-search-width: $size-208;
    $header-search-color: #b8b8b8;

    .header--filters__search--wrap {
      position: relative;
      height: $header-controls-height;
      margin: 0 $size-10;

      &.active {
        background-color: $filter-button-text-color;
        border-radius: $size-5;
      }

      &:not(.disabled):not(.active):hover {
        background-color: $component-bg-hover;
      }

      .header--filters__search {
        width: $header-search-width;
        height: $header-controls-height;
        line-height: $header-controls-height;
        outline: none;
        border-radius: $size-5;
        font-family: $font-semibold;
        font-size: $size-10;
        padding: 0 $size-10 0 $size-38;
        background-color: $header-search-bg-color;
        color: $header-search-color;
        border: $size-1 solid #4b4c4e;

        &::placeholder {
          color: $header-search-color;
        }
      }

      .header--filters__search--icon {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: $size-12;
        text-align: center;
        border-radius: $size-5 0 0 $size-5;
      }

      .header--filters__search--btn {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: $size-12;
        background-color: #4b4c4e;
        border-radius: $size-2;
        width: $size-30;
        height: $size-26;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .header--filters__search--box {
        display: flex;
        flex-direction: column;
        background-color: $header-search-bg-color;
        border: $size-1 solid #4b4c4e;
        border-bottom-left-radius: $size-4;
        border-bottom-right-radius: $size-4;
        width: $header-search-width;
        color: #fff;

        .checkboxes {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 100%;
          padding: $size-8 $size-10;

          label {
            font-size: $size-12;
          }

          .check-item {
            width: 50%;
            margin-bottom: $size-8;
          }
        }

        .doo-select {
          width: 100%;

          select {
            font-size: $size-8;
            height: $size-20;

            option {
              font-size: $size-8;
              background-color: $header-search-bg-color;
            }
          }
        }

        .row {
          display: flex;
          padding: 0 $size-10;
          margin-bottom: $size-10;
          align-items: center;

          span {
            font-size: $size-12;
            margin-right: $size-7;
            white-space: nowrap;
          }

          &.pixels {
            gap: $size-19;

            & > div {
              width: 33%;
            }
          }
        }

        .filter-search {
          background-color: transparent;
          border: $size-0-5 solid $component-border-color;
          border-radius: $size-2;
          outline: none;
          color: #fff;
          height: $size-20;
          width: 100%;
          font-size: $size-8;

          &::placeholder {
            color: #6b6b6b;
          }
        }

        .bg-color-container {
          position: relative;
          width: 100%;

          .filter-search {
            text-indent: $size-20;
          }

          .color-selector {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: $size-5;
            background-color: #fff;
            width: $size-13;
            height: $size-13;
            border-radius: $size-1;
            cursor: pointer;
          }
        }

        .separator {
          height: $size-1;
          background-color: $component-border-color;
          margin: 0 $size-10 $size-10;
        }

        .btn-apply-filter {
          background-color: $component-border-color;
          height: $size-24;
          line-height: $size-24;
          font-size: $size-10;
          color: #fff;
          border-radius: $size-3;
          width: fit-content;
          padding: 0 $size-20;
          margin: 0 auto $size-10;

          svg {
            position: relative;
            top: $size-3;
            margin-left: $size-5;
          }
        }
      }
    }
  }
}

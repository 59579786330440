@import "../../styles/variables.scss";

$slider-color: #ff9b42;
$slider-outside-color: #ffffff;
$slider-left-thumb-color: #333333;
$slider-right-thumb-color: #ffffff;

.doo-dual-range {
  position: relative;
  height: $size-28;

  .slider {
    -webkit-appearance: none; /* Removes default Chrome styling */
    position: relative;
    width: $size-300;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: $size-3;
    height: $size-3;
  }

  .slider__track {
    z-index: 1;
    background-color: $slider-outside-color;
    width: 100%;
    left: $size-2;
  }

  .slider__range {
    z-index: 2;
    background-color: $slider-color;
    margin-left: $size-2;
  }

  .slider__left-value,
  .slider__right-value {
    font-size: $size-10;
    margin-top: $size-10;
  }

  .slider__left-value {
    left: 0;
  }

  .slider__right-value {
    right: 0;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default styling */
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: $size-300;
    outline: none;
    margin-top: $size-1;
  }

  .thumb--left {
    z-index: 3;

    &::-webkit-slider-thumb {
      background-color: $slider-left-thumb-color;
    }
    &::-moz-range-thumb {
      background-color: $slider-left-thumb-color;
    }
  }

  .thumb--right {
    z-index: 4;

    &::-webkit-slider-thumb {
      background-color: $slider-right-thumb-color;
    }
    &::-moz-range-thumb {
      background-color: $slider-right-thumb-color;
    }
  }

  .thumb::-webkit-slider-thumb {
    border: $size-2 solid $slider-color;
    border-radius: 50%;
    cursor: pointer;
    height: $size-11;
    width: $size-11;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    border: $size-2 solid $slider-color;
    border-radius: 50%;
    cursor: pointer;
    height: $size-7;
    width: $size-7;
    pointer-events: all;
    position: relative;
  }
}

@import "../../../../styles/reset";

.checks-container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  margin-top: $size-6;
  padding: 0 $size-10;

  .divider {
    grid-column: 1 / span 3;
    border-bottom: $size-1 solid #4B4C4E;
  }

  .check-item {
    padding: $size-12 0;

    .checkbox-label {
      font-size: $size-14;
    }

    &.filename {
      grid-column: 1 / span 1;
    }

    &.format {
      grid-column: 2 / span 1;
    }

    &.width {
      grid-column: 1 / span 1;
    }

    &.colour-space {
      grid-column: 1 / span 3;
    }

    &.background {
      grid-column: 1 / span 3;
    }

    &.colour-sampler {
      grid-column: 1 / span 3;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

@import '../../../styles/reset';

.dialog--update {
  z-index: $z-index-overlay-controls;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(21, 27, 33, 0.22);
}

.dialog-loader--wrap {
  z-index: $z-index-overlay-controls;
  position: absolute;
  left: $size-16;
  bottom: $size-16;
  font-size: $size-13;
  color: #fff;
  background-color: rgba(51, 51, 51, 0.9);
  padding: $size-13 $size-26;
  border-radius: $size-5;
  width: fit-content;
}

@import '../../../styles/reset';

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  width: 100%;
  
  &--dot {
    animation: dot-keyframes 2s infinite ease-in-out;
    background-color: $dark-color;
    border-radius: $size-10;
    display: inline-block;
    height: $size-7;
    width: $size-7;
    margin-right: $size-4;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}
@import '../../../styles/reset';

.header {
  .header--image-types__wrap {
    display: flex;
    justify-content: space-between;
    width: $size-186;
    height: $size-38;
    background-color: $component-bg;
    color: #6A6A6A;
    border-radius: $size-5;
    padding: 0 $size-2;

    .header--image-types__wrap--type {
      cursor: pointer;
      text-align: center;
      width: calc((100% / 3) - $size-2);
      height: $size-34;
      font-size: $size-11;
      margin-top: $size-2;
      border-radius: $size-4;
    }

    .header--image-types__wrap--type:hover {
      background-color: $component-bg-hover;
    }

    .header--image-types__wrap--type__active, .header--image-types__wrap--type__active:hover {
      cursor: inherit;
      color: #fff;
      background: $component-bg-active;
      box-shadow: 0 $size-4 $size-19 rgba(0, 0, 0, 0.25);
    }

    .header--image-types__wrap--type__header {
      font-family: $font-semibold;
      margin-top: $size-4;
      font-size: $size-8;
    }

    .header--image-types__wrap--type__value {
      font-family: $font-semibold;
      font-size: $size-12;
    }
  }
}

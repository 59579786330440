@import "../../styles/variables.scss";
@import "../../styles/reset.scss";

$color-picker-dark-bg-color: #4b4c4e;

.color-picker {
  position: fixed;
  z-index: 1;
  background-color: $coloris-bg;
  width: $size-177;
  border: $size-0-5 solid #707070;
  border-radius: $size-3;
  padding: $size-10;

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $size-7;
  }

  .color:hover {
    cursor: pointer;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    margin-top: $size-10;
    gap: $size-4;

    .doo-select {
      select {
        font-size: $size-12;
        padding: 0 $size-2;
        height: 100%;
      }
    }

    svg {
      position: initial !important;
    }

    .color-code-input {
      outline: none;
      background-color: transparent;
      border: $size-0-5 solid #707070;
      border-radius: $size-2;
      color: #ffffff;
      height: $size-20;
      font-size: $size-12;

      /* Hide number input arrows */
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Hide number input arrows */

      &::placeholder {
        color: #bcbcbc66;
      }

      &::-ms-input-placeholder {
        color: #bcbcbc66;
      }

      &.hex {
        width: $size-79;
      }

      &.rgb {
        width: $size-24;
        text-align: center;
      }
    }
  }

  .color {
    width: $size-20;
    height: $size-20;
    line-height: $size-20;
    border-radius: $size-1;
    position: relative;
    text-align: center;
    font-size: $size-10;
    font-family: $font-semibold;

    .pencil {
      position: relative;
      width: $size-10;
      height: $size-10;
      bottom: -$size-10;
      right: -$size-13;

      svg {
        width: $size-10;
        height: $size-10;
      }
    }
  }
}

@import "../../styles/variables.scss";

.doo-select {
  position: relative;

  select {
    width: 100%;
    background-color: transparent;
    border: $size-0-5 solid $component-border-color;
    border-radius: $size-2;
    color: #fff;
  }
}

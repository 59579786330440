@import '../../../styles/variables.scss';

$info-bar-bg-color: #3F3F3F;

.info-bar {
  z-index: $z-index-preview;
  position: absolute;
  left: 0;
  top: $size-60;
  background-color: $info-bar-bg-color;
  padding: $size-10 $size-12;
  min-width: $size-208;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #FFFFFF;
  overflow-y: auto;
  font-family: $font;

  .info-bar_close {
    position: absolute;
    right: $size-10;
    top: $size-10;
    cursor: pointer;
    background-color: $info-bar-bg-color;
    border-radius: $size-5;
    width: $size-20;
    height: $size-20;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: darken($info-bar-bg-color, 5%);
    }

    svg {
      width: $size-7;
    }
  }

  .order-status {
    margin-bottom: $size-4;
  }

  .section {
    position: relative;
    margin-bottom: $size-10;
    margin-top: $size-10;

    &:nth-child(2) {
      margin-top: 0;
    }


    .section-header {
      margin-bottom: $size-4;
    }

    .collapse-btn {
      position: absolute;
      right: $size-5;
      top: $size-2;
      cursor: pointer;
      border-radius: $size-5;
      background-color: $info-bar-bg-color;
      width: $size-20;

      &:hover {
        background-color: darken($info-bar-bg-color, 5%);
      }
    }

    .check-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .check {
        margin-right: $size-5;
      }
    }
  }

  ul {
    list-style: disc;
    padding-left: $size-15;
    margin: 0;
    color: #FFFFFF;

    li {
      line-height: $size-24;
    }
  }

  .separator {
    border-top: $size-1 solid $component-border-color;
    width: $size-113;
    margin: 0 auto;
  }
}
/******************************************************/
$mobile-breakpoint-width: 431px;
$tablet-breakpoint-width: 881px;
$desktop-breakpoint-width: 1024px;
$hd-breakpoint-width: 2048px;

$register-ad-breakpoint-width: 1024px;
$login-ad-breakpoint-width: 1024px;
$support-image-breakpoint-width: 1160px;

$sidebar-breakpoint-width: 1441px;

:root {
  --root-size: 11px;
}

/* mobile devices */
@media (min-width: $mobile-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* tablet devices */
@media (min-width: $tablet-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* desktop devices */
@media (min-width: $desktop-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* hd devices */
@media (min-width: $hd-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/******************************************************/
$size-0: 0;
$size-0-5: .5px;
$size-1: 1px;
$size-1-5: 0.1153846rem;
$size-2: 0.1538461rem;
$size-3: 0.2307692rem;
$size-4: 0.3076923rem;
$size-5: 0.3846153rem;
$size-6: 0.4615384rem;
$size-7: 0.5384615rem;
$size-8: 0.6153846rem;
$size-9: 0.6923076rem;
$size-10: 0.7692307rem;
$size-11: 0.8461538rem;
$size-12: 0.9230769rem;
$size-13: 1.0000000rem;
$size-14: 1.0769230rem;
$size-15: 1.1538461rem;
$size-16: 1.2307692rem;
$size-17: 1.3076923rem;
$size-18: 1.3846153rem;
$size-19: 1.4615384rem;
$size-20: 1.5384615rem;
$size-21: 1.6153846rem;
$size-22: 1.6923076rem;
$size-23: 1.7692307rem;
$size-24: 1.8461538rem;
$size-25: 1.9230769rem;
$size-26: 2.0000000rem;
$size-27: 2.0769230rem;
$size-28: 2.1538461rem;
$size-29: 2.2307692rem;
$size-30: 2.3076923rem;
$size-31: 2.3846153rem;
$size-32: 2.4615384rem;
$size-33: 2.5384615rem;
$size-34: 2.6153846rem;
$size-35: 2.6923076rem;
$size-36: 2.7692307rem;
$size-37: 2.8461538rem;
$size-38: 2.9230769rem;
$size-39: 3.0000000rem;
$size-40: 3.0769230rem;
$size-41: 3.1538461rem;
$size-42: 3.2307692rem;
$size-43: 3.3076923rem;
$size-44: 3.3846153rem;
$size-45: 3.4615384rem;
$size-46: 3.5384615rem;
$size-47: 3.6153846rem;
$size-48: 3.6923076rem;
$size-49: 3.7692307rem;
$size-50: 3.8461538rem;
$size-51: 3.9230769rem;
$size-52: 4.0000000rem;
$size-53: 4.0769230rem;
$size-54: 4.1538461rem;
$size-55: 4.2307692rem;
$size-56: 4.3076923rem;
$size-57: 4.3846153rem;
$size-58: 4.4615384rem;
$size-59: 3.6875000rem;
$size-60: 4.6153846rem;
$size-61: 4.6923076rem;
$size-62: 4.7692307rem;
$size-63: 4.8461538rem;
$size-64: 4.9230769rem;
$size-65: 5.0000000rem;
$size-66: 5.0769230rem;
$size-67: 5.1538461rem;
$size-68: 5.2307692rem;
$size-69: 5.3076923rem;
$size-70: 5.3846153rem;
$size-71: 5.4615384rem;
$size-72: 5.5384615rem;
$size-73: 5.6153846rem;
$size-74: 5.6923076rem;
$size-75: 5.7692307rem;
$size-76: 5.8461538rem;
$size-77: 5.9230769rem;
$size-78: 6.0000000rem;
$size-79: 6.0769230rem;
$size-80: 6.1538461rem;
$size-83: 6.3846153rem;
$size-86: 6.6153846rem;
$size-88: 6.7692307rem;
$size-90: 6.9230769rem;
$size-91: 7.0000000rem;
$size-92: 7.0769230rem;
$size-93: 7.1538461rem;
$size-94: 7.2307692rem;
$size-96: 7.3846153rem;
$size-97: 7.4615384rem;
$size-100: 7.6923076rem;
$size-105: 8.0769230rem;
$size-109: 8.3846153rem;
$size-110: 8.4615384rem;
$size-112: 8.6153846rem;
$size-113: 8.6923076rem;
$size-114: 8.7692307rem;
$size-115: 8.8461538rem;
$size-116: 8.9230769rem;
$size-118: 9.0769230rem;
$size-120: 9.2307692rem;
$size-122: 9.3846153rem;
$size-124: 9.5384615rem;
$size-125: 9.6153846rem;
$size-126: 9.6923076rem;
$size-130: 10.000000rem;
$size-132: 10.153846rem;
$size-135: 10.384615rem;
$size-137: 10.538461rem;
$size-140: 10.769230rem;
$size-144: 11.076923rem;
$size-145: 11.153846rem;
$size-148: 11.384615rem;
$size-150: 11.538461rem;
$size-152: 11.692307rem;
$size-154: 11.846153rem;
$size-155: 11.923076rem;
$size-157: 12.076923rem;
$size-158: 12.153846rem;
$size-159: 12.230769rem;
$size-160: 12.307692rem;
$size-165: 12.692307rem;
$size-168: 12.923076rem;
$size-170: 13.076923rem;
$size-171: 13.153846rem;
$size-172: 13.230769rem;
$size-174: 13.384615rem;
$size-176: 13.538461rem;
$size-177: 13.615384rem;
$size-180: 13.846153rem;
$size-181: 13.923076rem;
$size-183: 14.076923rem;
$size-186: 14.307692rem;
$size-190: 14.615384rem;
$size-193: 14.846153rem;
$size-198: 15.230769rem;
$size-200: 15.384615rem;
$size-204: 15.692307rem;
$size-208: 16.000000rem;
$size-210: 16.153846rem;
$size-215: 16.538461rem;
$size-220: 16.923076rem;
$size-223: 17.153846rem;
$size-230: 17.692307rem;
$size-232: 17.846153rem;
$size-234: 18.000000rem;
$size-240: 18.461538rem;
$size-250: 19.230769rem;
$size-256: 19.692307rem;
$size-260: 20.000000rem;
$size-265: 20.384615rem;
$size-267: 20.538461rem;
$size-268: 20.615384rem;
$size-270: 20.769230rem;
$size-275: 21.153846rem;
$size-280: 21.538461rem;
$size-283: 21.769230rem;
$size-285: 21.923076rem;
$size-287: 22.076923rem;
$size-288: 22.153846rem;
$size-290: 22.307692rem;
$size-298: 22.923076rem;
$size-300: 23.076923rem;
$size-314: 24.153846rem;
$size-328: 25.230769rem;
$size-320: 24.615384rem;
$size-322: 24.769230rem;
$size-328: 25.230769rem;
$size-330: 25.384615rem;
$size-332: 25.538461rem;
$size-343: 26.461538rem;
$size-346: 26.615384rem;
$size-348: 26.769230rem;
$size-350: 26.923076rem;
$size-352: 27.076923rem;
$size-360: 27.692307rem;
$size-362: 27.846153rem;
$size-365: 28.076923rem;
$size-370: 28.461538rem;
$size-379: 29.153846rem;
$size-380: 29.230769rem;
$size-385: 29.615384rem;
$size-390: 30.000000rem;
$size-400: 30.769230rem;
$size-411: 31.615384rem;
$size-420: 32.307692rem;
$size-430: 33.076923rem;
$size-447: 34.384615rem;
$size-450: 34.615384rem;
$size-453: 34.846153rem;
$size-460: 35.384615rem;
$size-462: 35.538461rem;
$size-466: 35.846153rem;
$size-480: 36.923076rem;
$size-485: 37.307692rem;
$size-490: 37.692307rem;
$size-500: 38.461538rem;
$size-520: 40.000000rem;
$size-525: 40.384615rem;
$size-530: 40.769230rem;
$size-538: 41.384615rem;
$size-550: 42.307692rem;
$size-558: 43.076923rem;
$size-560: 43.076923rem;
$size-563: 43.307692rem;
$size-566: 43.538461rem;
$size-570: 43.846153rem;
$size-575: 44.230769rem;
$size-578: 44.461538rem;
$size-587: 45.230769rem;
$size-592: 45.538461rem;
$size-598: 46.000000rem;
$size-600: 46.153846rem;
$size-614: 47.230769rem;
$size-620: 47.692307rem;
$size-624: 48.000000rem;
$size-630: 48.461538rem;
$size-638: 49.076923rem;
$size-642: 49.384615rem;
$size-645: 49.615384rem;
$size-700: 53.846153rem;
$size-715: 55.000000rem;
$size-768: 59.076923rem;
$size-800: 61.538461rem;
$size-840: 64.615384rem;
$size-900: 69.230769rem;
$size-938: 72.307692rem;
$size-948: 72.923076rem;
$size-1000: 76.92307rem;
$size-1248: 96.00000rem;

/******************************************************/
@font-face {
  font-family: 'roobert';
  src: url('../../public/fonts/Roobert-Regular.woff2') format('woff2'), url('../../public/fonts/Roobert-Regular.woff') format('woff'), url('../../public/fonts/Roobert-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-italic';
  src: url('../../public/fonts/Roobert-RegularItalic.woff2') format('woff2'), url('../../public/fonts/Roobert-RegularItalic.woff') format('woff'), url('../../public/fonts/Roobert-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-medium';
  src: url('../../public/fonts/Roobert-Medium.woff2') format('woff2'), url('../../public/fonts/Roobert-Medium.woff') format('woff'), url('../../public/fonts/Roobert-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-semibold';
  src: url('../../public/fonts/Roobert-SemiBold.woff2') format('woff2'), url('../../public/fonts/Roobert-SemiBold.woff') format('woff'), url('../../public/fonts/Roobert-SemiBold.otf') format('opentype');
}

$font: roobert, helvetica, arial, sans-serif;
$font-italic: roobert-italic;
$font-medium: roobert-medium;
$font-semibold: roobert-semibold;

/******************************************************/
$bg-color: #fff;
$lighter-color: #E2E2E2;
$light-color: #667085;
$dark-color: #475467;
$light-grey-color: #808185;
$input-error-color: #C65054;
$link-color: #00A7E1;

/******************************************************/
$text-selection-color: #fff;
$text-selection-bg: #4bc0ff;

/******************************************************/
$break-word: break-word;
$overflow-overlay: overlay; /* the same as auto, but with the scrollbars drawn on top of content instead of taking up space */
$center-top: 44%;

/******************************************************/
$z-index-gallery--menu-settings: 1;
$z-index-gallery--menu: 2;

$z-index-preview: 1;
$z-index-grid: 2;
$z-index-guide: 3;
$z-index-drawing: 4;
$z-index-header: 888;
$z-index-overlay: 999;
$z-index-modal: 999;
$z-index-draggable: 1000;
$z-index-overlay-controls: 1111;
$z-index-draggable-select: 9999;

/******************************************************/
$component-color: #F5F5F5; /* default color of component */
$component-color-disabled: #6B6B6B;  /* disabled color of component (not clickable) */
$header-component-color-disabled: #6b6b6b; /* disabled color of component (not clickable) */

$component-bg: #292929; /* default background color of component */
$component-bg-hover: #3B3B3B; /* hover background color of component */
$component-bg-active: #4B4C4E; /* active background color of component (currently selected) */

$component-border-color: #4B4C4E; /* default border color of component */

/******************************************************/
$header-height: $size-56;
$gallery-min-width: $tablet-breakpoint-width;

/******************************************************/
$header-bg: #333333;
$header-controls-width: $size-40;
$header-controls-height: $size-38;
$header-search-bg-color: #333333;

/******************************************************/
.roobert-italic {
  font-family: $font-italic;
}

.roobert-medium {
  font-family: $font-medium;
}

.roobert-semibold {
  font-family: $font-semibold;
}

@mixin font-mixing {
  font-family: $font;
  font-weight: 400;
  font-size: var(--root-size);
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$scrollbar-thumb-bg: rgba(217, 217, 217, 0.2);
$scrollbar-thumb-bg-light: #a1a1a1;

/******************************************************/
$loader-color: #00A7E1;
$waring-color: #FFA117;
$gallery-error-color: #d50000;

$gallery-details-error-color: #994751;
$gallery-details-error-bg-color: #ffdada;

$menu-border-radius: $size-5;

/******************************************************/
$image-wrap-padding: 0px; // in px, because is used in JS
$small-images-min-width: 200px; // in px, because is used in JS
$medium-images-min-width: 600px; // in px, because is used in JS

/******************************************************/
$image-wrap-color: #fff;
$image-wrap-bg-color: #666;
$image-buttons-bg-color: #4B4C4E;
$image-buttons-bg-color-hover: #2a2b2d;

$image-wrap-error-bg-color: #994751;
$image-buttons-error-bg-color: #921120;
$image-buttons-error-bg-color-hover: #640510;

$image-wrap-confirmed-bg-color: #8EA49D;
$image-buttons-confirmed-bg-color: #526660;
$image-buttons-confirmed-bg-color-hover: #313d39;

$image-wrap-reference-color: #4B4C4E;
$image-wrap-reference-bg-color: #D1D1D1;
$image-buttons-reference-bg-color: #575961;
$image-buttons-reference-bg-color-hover: #303239;

$image-wrap-missing-color: #4B4C4E;
$image-wrap-missing-bg-color: #D1D1D1;
$image-buttons-missing-bg-color: #67686c;
$image-buttons-missing-bg-color-hover: #3f4146;

$image-wrap-broken-bg-color: #E91E63;
$image-buttons-broken-bg-color: #940737;
$image-buttons-broken-bg-color-hover: #5e0020;

$image-wrap-selected-bg-color: #5B7FBE;
$image-buttons-selected-bg-color: #455F8D;
$image-buttons-selected-bg-color-hover: #5e0020;

/******************************************************/
$notice-color: #DB5375;
$client-comment-bg: #DB5375;
$client-comment-approved-bg: #85B79D;

$comment-icon-client-size: $size-15;

/******************************************************/
$header-hamburger-height: $size-16;
$header-avatar-border: $size-2;

/******************************************************/
$overlay-controls-bg: $component-bg;
$overlay-controls-bg-hover: $component-bg-hover;
$overlay-controls-bg-active: $component-bg-active;
$overlay-left-right-panel-bg-color: #333333;

/******************************************************/
$not-found-color: #fff;
$error-type-color: #f6f6f6;
$error-descr-color: #3A3183;


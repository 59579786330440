@import "../../styles/variables.scss";

.radio-group-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $size-6;

  .radio-container {
    display: flex;
    gap: $size-8;
    /*color: #fff;*/ // it's dangerous to have colors in generic component
    width: 100%;

    &:not(:last-child) {
      /*border-bottom: $size-11 solid #3a3a3a;*/ // it's dangerous to have colors in generic component
      padding-bottom: $size-8;
    }

    .radio-hidden {
      display: none;
    }

    .selected,
    .unselected {
      display: flex;
      align-items: center;
    }
  }
}

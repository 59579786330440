@import "../../../../styles/variables.scss";

.comment-section {
  position: relative;
  margin: auto $size-12 $size-16 $size-21;
  border: $size-0-5 solid #707070;
  border-radius: $size-3;
  background-color: $overlay-left-right-panel-bg-color;
  width: $size-346;

  .comment-textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    color: #fff;
    border: none;
    border-top-left-radius: $size-5;
    border-top-right-radius: $size-5;
    padding: $size-8;
    text-indent: $size-25;
    margin: 0;
    min-height: $size-70;
    height: $size-70;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #6c6c6c;
    }
  }

  .comment-icon {
    position: absolute;
    top: $size-10;
    left: $size-10;
    cursor: text;
  }

  .comment-section__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: $size-5;
    border-bottom-right-radius: $size-5;
    height: $size-30;
    padding: 0 $size-7 $size-5 $size-10;

    .comment-section__styles {
      display: flex;
      gap: $size-10;

      .comment-section__color {
        display: flex;

        .color-picker {
          bottom: $size-5;
        }
      }

      .comment-section__style-button {
        border-radius: $size-2;
        width: $size-22;
        height: $size-22;
        background-color: transparent;

        &:not(.disabled):not(.active):hover {
          background-color: $component-bg-hover;
        }
      }
    }

    .comment-section__button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $size-9;
      width: $size-110;
      height: $size-24;
      background-color: #4b4c4e;
      color: #fff;
      font-size: $size-10;
      border-radius: $size-3;
    }
  }
}

@import "../../../../styles/variables.scss";

$collapse-comment-height: $size-380;

.collapsible-comment {
  z-index: $z-index-overlay-controls;
  position: absolute;
  right: $size-12;
  bottom: 0;
  min-height: $size-348;
  background-color: #333333b2;
  border-top-left-radius: $size-5;
  border-top-right-radius: $size-5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .collapse-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $size-15;
    height: $collapse-comment-height;
    cursor: pointer;
    background-color: #33333380;
    border-top-left-radius: $size-5;
  }

  .collapsible-comment__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: $size-380;
    height: $collapse-comment-height;

    .collapsible-comment__active-comments {
      margin-top: $size-18;
      margin-bottom: $size-20;
      padding: 0 $size-12 0 $size-12;

      .collapsible-comment__active-comments-title {
        font-family: $font-medium;
        margin-bottom: $size-15;
      }

      .overlay--comment__wrap {
        padding: 0;
        margin-bottom: $size-10;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";

.shortcuts-container {
  z-index: $z-index-draggable-select;
  position: absolute;
  top: $size-10;
  left: $size-10;
  background-color: #333333;
  border: $size-1 solid #c4c4c4;
  border-radius: $size-5;
  min-width: $size-400;

  .top {
    display: flex;
    justify-content: space-evenly;
    padding: $size-7 0;

    .shortcut-btn {
      color: #e1e1e1;
      font-size: $size-12;
      font-family: $font-medium;
      background-color: #333333;
      width: $size-100;
      height: $size-30;
      line-height: $size-29;
      border-radius: $size-5;
      text-align: center;

      &:hover {
        cursor: pointer;
        background-color: #4b4c4e;
      }

      &.active {
        background-color: #4b4c4e;
      }

      &:not(.disabled):not(.active):hover {
        background-color: $overlay-controls-bg-hover;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: $size-8;
    padding: $size-10 $size-8 $size-20 $size-8;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 $size-5;
      height: $size-25;
      border-radius: $size-3;

      &:hover {
        background-color: $overlay-controls-bg-hover;
      }

      .left {
        font-family: $font-medium;
        font-size: $size-12;
      }

      .right {
        width: $size-130;
        display: flex;
      }

      .shortcuts {
        display: flex;

        .shortcut {
          display: flex;
          justify-content: center;
          align-items: center;
          border: $size-1 solid #a2befa;
          border-radius: $size-4;
          font-size: $size-12;
          font-family: $font-semibold;
          color: #a2befa;
          width: $size-24;
          height: $size-24;
          min-width: fit-content;
          padding: $size-4;
        }

        .or {
          display: flex;
          justify-content: center;
          align-items: center;
          height: $size-24;
          min-width: fit-content;
          padding: $size-4;
        }

        &:first-of-type {
          margin-left: auto;
        }
      }
    }
  }
}

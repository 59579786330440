@import "../../styles/variables.scss";

$tooltip-bg-color: #000000;
$tooltip-shadow-color: #00000040;
$tooltip-text-color: #fff;
$check-color: #8acb88;

.tooltip {
  display: inline-block;
  font-size: inherit;
  position: relative;
  cursor: pointer;

  .tooltip-tooltip__content {
    z-index: $z-index-draggable-select;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $size-4;
    box-shadow: 0 $size-4 $size-4 0 $tooltip-shadow-color;
    visibility: visible;
    background-color: $tooltip-bg-color;
    color: $tooltip-text-color;
    text-align: center;
    border-radius: $size-2;
    padding: $size-5 $size-5;
    font-size: $size-12;
    white-space: nowrap;
    width: fit-content;

    &::after {
      content: "";
      position: absolute;
      margin-left: (-$size-5);
      border-width: $size-5;
      border-style: solid;
    }

    &.copy-text,
    &.copied-text {
      font-size: inherit;
    }

    &.tooltip-top {
      bottom: 140%;
      left: 50%;
      transform: translateX(-50%);

      &::after {
        top: 100%;
        border-color: $tooltip-bg-color transparent transparent transparent;
        left: 50%;
      }
    }

    &.tooltip-bottom {
      top: 140%;
      left: 50%;
      transform: translateX(-50%);

      &::after {
        top: -($size-9);
        border-color: transparent transparent $tooltip-bg-color transparent;
        left: 50%;
      }
    }

    &.tooltip-left {
      right: 130%;
      top: 50%;
      transform: translateY(-50%);

      &::after {
        right: -$size-9;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent transparent transparent $tooltip-bg-color;
      }
    }

    &.tooltip-right {
      left: 130%;
      top: 50%;
      transform: translateY(-50%);

      &::after {
        left: -$size-4;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent $tooltip-bg-color transparent transparent;
      }
    }

    .copied-svg {
      path {
        fill: $check-color;
      }
    }
  }
}

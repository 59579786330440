@import '../../../styles/reset';

.comment-textarea {
  width: 100%;
  height: $size-100;
  margin-top: $size-10;
  padding: $size-10;
  border-radius: $size-5;
  resize: none;
  border: none;
  background-color: #3A3A3A;
  color: #fff;

  &::placeholder {
    color: #6C6C6C;
  }

  &:focus {
    outline: none;
  }
}

@import '../../../styles/reset';

.gallery-image--wrap {
  display: flex;
  flex-direction: column;
  min-width: calc($small-images-min-width + $image-wrap-padding * 2);
  margin: 0 $size-10 $size-13 $size-10;
  background-color: $image-wrap-bg-color;
  color: $image-wrap-color;
  /*border-radius: 0 0 $size-5 $size-5;*/

  .image-preview--details__actions {
    background-color: $image-buttons-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-bg-color-hover;
    }
  }
}

.empty-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $size-14;
  max-width: $size-642;
  padding-top: $size-314;

  h6 {
    font-family: $font-semibold;
    text-align: center;
  }

  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }
}

.gallery-image--wrap__error {
  background-color: $image-wrap-error-bg-color;

  .image-preview--details__actions {
    background-color: $image-buttons-error-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-error-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-error-bg-color-hover;
    }
  }

  .image-error {
    /*border: $size-1 solid #fff;*/
  }
}

.gallery-image--wrap__reference {
  color: $image-wrap-reference-color;
  background-color: $image-wrap-reference-bg-color;

  .image-preview--details__filename--id {
    color: $image-wrap-reference-color;
  }

  .image-preview--details__actions {
    background-color: $image-buttons-reference-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-reference-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-reference-bg-color-hover;
    }
  }
}

.gallery-image--wrap__confirmed {
  background-color: $image-wrap-confirmed-bg-color;

  .image-preview--details__actions {
    background-color: $image-buttons-confirmed-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-confirmed-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-confirmed-bg-color-hover;
    }
  }
}

.gallery-image--wrap__missing {
  background-color: $image-wrap-missing-bg-color;
  color: $image-wrap-missing-color;

  .image-preview--details__actions {
    background-color: $image-buttons-missing-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-missing-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-missing-bg-color-hover;
    }
  }
}

.gallery-image--wrap__broken {
  background-color: $image-wrap-broken-bg-color;

  .image-preview--details__actions {
    background-color: $image-buttons-broken-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: $image-buttons-broken-bg-color-hover;
    }

    &:not(.disabled):not(.active):hover {
      background-color: $image-buttons-broken-bg-color-hover;
    }
  }

  .image-error {
    border: $size-1 solid #fff;
  }
}

.gallery-image--wrap__selected {
  color: #ffffff;
  background-color: $image-wrap-selected-bg-color !important;
  box-shadow: 0 0 $size-3 0 $image-wrap-selected-bg-color;

  .image-preview--bg {
    box-shadow: 0 (-$size-5) 0 0 $image-wrap-selected-bg-color;
  }

  .image-preview--details__actions {
    background-color: $image-buttons-selected-bg-color;
  }

  .image-preview--details__actions-action {
    &.active {
      background-color: darken($color: $image-buttons-selected-bg-color, $amount: 10);
    }

    &:not(.disabled):not(.active):hover {
      background-color: darken($color: $image-buttons-selected-bg-color, $amount: 10);
    }
  }
}

.loader--wrap {
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -48%);
  text-align: center;
}
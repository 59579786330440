@import "../../styles/variables";

$slider-color: #f5f5f5;
$slider-border-width: $size-1;
$slider-thumb-size: $size-13;

.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $size-5;
  
  .slider {
    -webkit-appearance: none; /* Removes default Chrome styling */
    height: $size-3;
    border-radius: $size-2;
    background: $slider-color;
    outline: none;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default styling */
    appearance: none;
    width: $slider-thumb-size;
    height: $slider-thumb-size;
    border-radius: 50%;
    background: #333333;
    cursor: pointer;
    border: $slider-border-width solid $slider-color;
  }

  /* For Firefox browsers */
  .slider::-moz-range-thumb {
    width: $slider-thumb-size;
    height: $slider-thumb-size;
    border-radius: 50%;
    background: #333333;
    cursor: pointer;
    border: $slider-border-width solid $slider-color;
  }

  span {
    font-size: $size-11;
  }
}

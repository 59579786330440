@import '../../styles/variables.scss';

.checkbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: inherit;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .checkbox-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkbox-label {
    padding: 0 $size-4;
    margin-left: $size-2;
    font-size: $size-12;
  }
}
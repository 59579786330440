@import "../../../../styles/variables";

.overlay-avatars {
  z-index: $z-index-overlay-controls;
  position: absolute;
  top: $size-5;
  right: $size-8;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: $size-1 solid transparent;
  background-color: transparent;
  width: fit-content;
  padding: $size-8;
  border-radius: $size-2;

  &__hover {
    background-color: #333;
    border: $size-1 solid $component-border-color;
  }

  .header--avatars__wrap {
    display: flex;
    flex-direction: row-reverse;

    .header--avatar {
      position: relative;
      width: $size-32;
      height: $size-32;
      line-height: calc($size-32 - $header-avatar-border);
      color: #fff;
      background-color: $component-bg-active;
      border: $header-avatar-border solid $header-bg;
      font-family: $font-semibold;
      font-size: $size-10;
      border-radius: 50%;
      text-align: center;
    }

    .header--avatar__client {
      top: $size-1;
      width: $size-30;
      height: $size-30;
      line-height: calc($size-30 - $header-avatar-border);
      color: $header-bg;
      background-color: #fff !important;
      border: $header-avatar-border dotted $header-bg;
    }
  }

  .header-users {
    color: #fff;
    margin-right: $size-8;
    max-width: $size-300;
    line-height: normal;

    span {
      font-size: $size-12;
    }
  }
}

@import '../../../../styles/reset';

$menu-bg-color: #333333;
$menu-bg-hover-color: #4B4C4E;

/******************************************************/
.image-preview--error__wrap {
  background-color: $gallery-error-color;
}

.image-warning {
  color: $image-wrap-reference-color;
  background-color: $image-wrap-reference-bg-color;
  padding: 0 $size-3;
  border: $size-1 solid $image-wrap-reference-bg-color;
  border-radius: $size-3;
  font-size: $size-11;

  &:not(:first-of-type) {
    margin-top: $size-1;
  }
}

.image-error {
  color: #333;
  background-color: #fff;
  padding: 0 $size-3;
  border: $size-1 solid #d41500;
  border-radius: $size-3;
  font-size: $size-11;
}

.image-preview,
.image-preview--grid,
.image-preview--guide,
.image-preview--underlay {
  z-index: $z-index-preview;
  position: absolute;
  top: $image-wrap-padding;
  left: $image-wrap-padding;
}

.image-preview {
  background: transparent center center/cover no-repeat;
}

.image-preview--underlay {
  max-width: 100%;
  max-height: 100%;
}

.image-preview--guide,
.image-overlay--guide {
  z-index: $z-index-guide;
}

.image-preview--grid,
.image-overlay--grid {
  z-index: $z-index-grid;
}

.image-preview--bg {
  cursor: pointer;
  overflow-y: hidden;
  position: relative;
  text-align: center;
  padding: $image-wrap-padding;
  margin: 0 auto;

  .reference-badge {
    z-index: $z-index-preview;
    position: absolute;
    top: $size-4;
    right: $size-4;
    width: $size-40;
    height: $size-24;
    padding: $size-4 $size-5 $size-3 $size-5;
    background-color: $menu-bg-hover-color;
    border-radius: $size-2;
    font-size: $size-6;
    line-height: $size-6;
    text-align: center;
    color: #fff;

    svg {
      position: relative;
    }
  }

  .image-test-badge {
    z-index: $z-index-preview;
    position: absolute;
    top: $size-4;
    right: $size-4;
    background-color: #FFA117;
    color: #000;
    font-size: $size-10;
    padding: $size-4 $size-5;
    border-radius: $size-2;
  }

  svg,
  canvas {
    z-index: $z-index-preview;
    position: absolute;
    top: 0;
    left: 0;
  }
}

$image-preview-border-color: rgba(255, 255, 255, 0.25);
$image-preview-padding: $size-7;

.image-preview--space {
  height: $size-20;
  min-height: $size-20;
}

.image-preview--comments-header {
  text-align: center;
  padding-bottom: $size-2;
  /*font-family: $font-semibold;*/
}

.image-preview--details {
  padding: $size-6 $image-preview-padding;

  div, span {
    font-size: $size-12;
  }
}

.image-preview--details__actions--wrap {
  padding: 0 $image-preview-padding;
}

.image-preview--details__border {
  border-bottom: $size-1 solid $image-preview-border-color;
  padding: $size-6 0;
}

.image-preview--details__border:last-of-type {
  border-bottom: none;
}

.image-preview--details__edited-by {
  cursor: pointer;
}

.image-preview--details__exif, .image-preview--details__photoshop, .image-preview--details__label-recognition  {
  max-height: $size-80;
  word-break: $break-word;
}

.image-preview--details__path {
  max-height: $size-100;

  .checkbox-container {
    width: 100%;

    label:last-of-type {
      width: 100%;
    }
  }

  .path-item {
    display: flex;
    flex-direction: row;
    gap: $size-5;
    align-items: center;
    font-size: $size-12;
    padding: 0 $size-4 0 $size-4;
    border-radius: $size-2;

    &:hover {
      background-color: $component-bg-hover;
    }

    .path-color {
      position: relative;
      border-radius: $size-1;
      width: $size-10;
      height: $size-10;
      min-width: $size-10;
      min-height: $size-10;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.edited-by--crossed-name {
  text-decoration: line-through;
}

.image-preview--details__filename, .image-preview--details--folder {
  white-space: nowrap;
}

.image-preview--details__filename--id {
  padding-right: $size-7;
  color: rgba(255, 255, 255, 0.3);
}

.image-preview--details__sizes--wrap {
  div {
    line-height: $size-18;
  }
}

.image-preview--details__layers {
  padding: $size-6 0;
  border-bottom: $size-1 solid $image-preview-border-color;

  .radio-group-container {
    gap: $size-2;

    .radio-container {
      gap: $size-4;
      padding: 0 $size-4 0 $size-4;
      border-radius: $size-2;

      &:hover {
        background-color: $component-bg-hover;
      }

      label:last-of-type {
        width: 100%;
      }

      .layer-item {
        display: flex;
        align-items: center;
        gap: $size-4;

        svg {
          min-width: $size-14;
        }
      }
    }
  }
}

.image-preview--details__actions--wrap {
  padding-top: $size-8;
  padding-bottom: $size-8;
  margin-top: auto;
}

.image-preview--details__actions {
  display: flex;
  justify-content: space-around;
  height: $size-25;
  background-color: $image-buttons-bg-color;
  border-radius: $size-5;
  padding: $size-4 $size-4;
  position: relative;

  .bottom-menu {
    z-index: $z-index-preview;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $menu-bg-color;
    right: $size-1;
    bottom: $size-18;
    border-radius: $size-4;
    height: $size-52;
    white-space: nowrap;

    .bottom-menu--item {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: $size-6;
      height: $size-20;
      font-size: $size-12;
      padding: 0 $size-11;
      color: #fff;

      &:hover {
        background-color: $menu-bg-hover-color;
        cursor: pointer;
      }

      svg {
        position: relative;
      }
    }
  }
}

.image-preview--details__actions-action {
  cursor: pointer;
  text-align: center;
  width: $size-20;
  height: $size-17;
  margin: 0 $size-4;
  border-radius: $size-3;
}

.image-preview--details__actions-action svg {
  position: relative;
}

.draggable-wrap {
  z-index: $z-index-draggable;
  position: fixed;
  top: $size-100;
  left: $size-50;
  display: flex;
  cursor: move;
  min-height: $size-200;

  .draggable-close {
    position: absolute;
    top: (-$size-13);
    right: (-$size-13);
    width: $size-26;
    height: $size-26;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
  }

  .image-draggable {
    min-width: $medium-images-min-width;
  }
}

.gallery-image--wrap__reference {
  .path-item:hover {
    color: #fff;
  }
}

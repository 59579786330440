@import '../../../../styles/variables.scss';

.overlay--comment__wrap {
  &:hover {
    .overlay--comment__delete {
      display: flex;
    }
  }

  .overlay--comment__icon {
    position: relative;
    width: $comment-icon-client-size;
    height: $comment-icon-client-size;
    min-width: $comment-icon-client-size;
    min-height: $comment-icon-client-size;
    margin-right: $size-6;

    &.overlay--comment__icon--rejected {
      top: $size-3;
      left: $size-1;
      margin-right: $size-9;
    }
  }

  .overlay--comment__icon svg {
    position: absolute;
  }

  .overlay--comment__icon--status svg {
    top: $size-1;
    left: $size-3;
  }

  .overlay--comment__type svg {
    top: $size-4;
  }

  .overlay--comment__icon--client {
    top: $size-3;
    font-family: $font-semibold;
    font-size: $size-8;
    line-height: calc($comment-icon-client-size + $size-2);
    border-radius: $size-2;
    text-align: center;
    margin-right: $size-8;
    background-color: $client-comment-bg;
  }

  .overlay--comment__icon--client__approved {
    background-color: $client-comment-approved-bg;
  }

  .overlay--comment__delete {
    margin-left: auto;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: $size-2;
    padding: $size-4;
    display: none;
    position: absolute;
    top: $size-14;
    transform: translateY(-50%);
    right: $size-6;

    svg {
      width: $size-10;
      height: $size-10;

      path {
        fill: #333;
      }
    }
  }
}

.overlay--comment__wrap:hover {
  cursor: pointer;
  background-color: $component-bg-hover;
}